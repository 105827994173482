/**
 * Generates HTML and json-ld breadcrumbs
 */
import { useMatches } from "@remix-run/react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import Icon from "~/components/Icon";
import { getLocale } from "~/i18n";

const Breadcrumbs = () => {
  const { t } = useTranslation("translations");
  const locale = getLocale(useLocation().pathname);

  const matches = useMatches();
  const breadcrumbs: { title: string, to: string }[] = [];

  matches
    .filter((match) => match.handle?.breadcrumbs || match.data?.meta?.breadcrumbs)
    .map((match) => (match.handle?.breadcrumbs ?? match.data.meta?.breadcrumbs).map(
      (breadcrumb: { title: string, to: string }) => breadcrumbs.push(breadcrumb)
    ));

  const baseUrl = typeof process != "undefined"
    ? (process.env.BASE_URL ?? null)
    : window.location.origin;

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": t("home"),
        "item": `${baseUrl}/${locale}`
      }
    ]
  };

  breadcrumbs.map((breadcrumb: { title: string, to: string }, index: number) => {
      const item: { "@type": string, position: number, name: string, item?: string } = {
        "@type": "ListItem",
        "position": index + 2,
        "name": t(breadcrumb.title)
      };

      if (index !== breadcrumbs.length - 1) {
        item.item = `${baseUrl}/${locale}/${breadcrumb.to}`;
      }

      jsonLd.itemListElement.push(item);
    }
  );

  return breadcrumbs.length > 0 && (
    <>
      <div className="bg-slate-200 dark:bg-slate-600 py-2 overflow-auto flex-nowrap text-nowrap">
        <nav aria-label="breadcrumb">
          <ul className={classNames(
            "container flex flex-row space-x-2 items-center px-4 mx-auto text-[0.6rem] md:text-sm"
          )}>
          <li className="flex items-center">
            <Link to={`/${locale}`} className="opacity-80 hover:opacity-100 border-0 min-w-[15px] max-w-[20px]" title={t("home")}>
              <Icon v="home" className="m-h-[100px]" />
            </Link>
            <span className="pl-2">/</span>
          </li>
          {breadcrumbs.map((breadcrumb: { title: string, to: string }, index: number) => (
              <li key={index}
                  className="flex"
              >
                {index !== breadcrumbs.length - 1 ? (
                  <Link to={`/${locale}/${breadcrumb.to}`} className="opacity-80 hover:opacity-100 border-0">
                    {t(breadcrumb.title)}
                  </Link>
                ) : (
                  <span
                    className="dark:text-gray-300"
                    {...(index === breadcrumbs.length - 1 ? { "aria-current": "page" } : {})}
                  >
                  {t(breadcrumb.title)}
                </span>
                )}

                {index < breadcrumbs.length - 1 ? <span className="pl-2">/</span> : null}
              </li>
            )
          )}
          </ul>
        </nav>
      </div>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }} />
    </>
  );
};

export default Breadcrumbs;