import React, { useEffect } from "react";

import { useTheme } from "~/components/ThemeProvider";

/**
 * If Yandex RTB init scripts are put directly into header
 * it causes hydration error.
 * To overcome this, I add it after the component is mounted
 *
 * Scripts cant be rendered here as nomrmal TSX markup,
 * as they will not be executed as react uses innerHTML to mount DOM.
 * So I create them manually
 */
const YandexAdsInitScript: React.FC = () => {
  const [theme] = useTheme();

  useEffect(() => {
    const head = document.querySelector("head");
    if (!head) {
      console.error("Can't mount Yandex RTB script: head element not found");
      return;
    }

    const script1 = document.createElement("script");
    script1.innerHTML = `window.yaContextCb=window.yaContextCb||[]`;

    const script2 = document.createElement("script");

    script2.setAttribute("src", "https://yandex.ru/ads/system/context.js");
    head.appendChild(script1);
    head.appendChild(script2);

    setTimeout(() => {
      // Yandex Floor Ad
      const adId = "R-A-7013740-5";

      /** @ts-expect-error External library */
      window?.yaContextCb?.push(() => {
        Ya.Context.AdvManager.render({
          "blockId": adId,
          "type": "floorAd",
          "platform": "touch",
          darkTheme: theme === "dark",
          onRender: () => {
            console.log(`Ad ${adId} rendered`);
          },
          onError: (type, code, text) => {
            console.error(`Ad ${adId} failed to render`, type, code, text);
          }
        });
      });

    }, 3000);


    /**
     * Slowing down the add rendering to try to fix google ranking drop
     * TODO Can also be shown only on second navigation
     */
    setTimeout(() => {
      // Full screen ad
      const adId = "R-A-7013740-6";

      /** @ts-expect-error External library */
      window?.yaContextCb?.push(() => {
        Ya.Context.AdvManager.render({
          "blockId": adId,
          "type": "fullscreen",
          "platform": "touch",
          darkTheme: theme === "dark",
          onRender: () => {
            console.log(`Ad ${adId} rendered`);
          },
          onError: (type, code, text) => {
            console.error(`Ad ${adId} failed to render`, type, code, text);
          }
        });
      });

    }, 20000);
    return () => {
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);

  return null;
};

export default YandexAdsInitScript;