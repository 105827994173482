import { YMInitializer } from "@appigram/react-yandex-metrika";
import { cssBundleHref } from "@remix-run/css-bundle";
import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration, useLoaderData,
  useLocation,
  useMatches,
  useRouteError } from
"@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useChangeLanguage } from "remix-i18next/react";

import GoogleAdSenseInitScript from "~/components/ads/GoogleAdSenseInitScript";
import YandexAdsInitScript from "~/components/ads/YandexAdsInitScript";
import { ThemeInitScript, ThemeProvider, useTheme } from "~/components/ThemeProvider";
import { getLocalisedLinks, LocalisedLink } from "~/helpers/localisedLinks";
import i18n, { getLocale } from "~/i18n";
import Footer from "~/routes/_index/Footer";
import Header from "~/routes/_index/Header";
import stylesheet from "~/tailwind.css";


export const links: LinksFunction = () => [
{ rel: "icon", href: "/favicon.ico", sizes: "48x48" },
{ rel: "icon", href: "/favicon.svg", sizes: "any", type: "image/svg+xml" },
{ rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
{ rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
{ rel: "apple-touch-icon", href: "/apple-touch-icon.png" },
{ rel: "manifest", href: "/site.webmanifest" },
{ rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
{ rel: "manifest", href: "/site.webmanifest" },
{ rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#fb7185" },

{ rel: "stylesheet", href: stylesheet },
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : [])];


export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: "translations"
};


export const loader = async ({ request }: LoaderFunctionArgs) => {
  return json({
    meta: {
      showAds: process.env.ADS_ENABLED === "true",
      isDevMode: process.env.NODE_ENV === "development",
      analyticsEnabled: process.env.ANALYTICS_ENABLED !== "false",
      sentryDSN: process.env.SENTRY_DSN ?? null
    },
    user: { userId: 1 }
  });
};


export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);

  const locale = getLocale(useLocation().pathname) ?? i18n.fallbackLng;

  const isDevelopment = process.env.NODE_ENV === "development";

  captureRemixErrorBoundaryError(error);

  return (
    <html lang={locale} className="h-screen">
    <head>
      <title>Oh no!</title>
      <link rel="icon" href="data:image/x-icon;base64,AA" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="apple-mobile-web-app-title" content="CountLike.PRO" />
      <meta name="application-name" content="CountLike.PRO" />
      <meta name="msapplication-TileColor" content="#ff0000" />
      <meta name="theme-color" content="#ffffff" />
      <Meta />
      <Links />
    </head>
    <body className="bg-indigo-800 text-white h-full min-h-full">
    <div className="container h-full px-6 py-3 mx-auto flex flex-col items-center text-center text-xl justify-center">
      <h1 className="font-bold">Oh no!</h1>
      <p className="mb-6">We're so sorry, some error has happened...</p>

      {isRouteErrorResponse(error) ? <div>
        <h1 className="text-4xl font-extrabold">
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div> : null}
      {isDevelopment && !isRouteErrorResponse(error) && error instanceof Error ? <div className="text-red-500">
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre>{error.stack}</pre>
      </div> : null}
      {isDevelopment && !isRouteErrorResponse(error) && !(error instanceof Error) ? <h1>Unknown Error</h1> : null}
    </div>
    <Scripts />
    </body>
    </html>);

}

function App() {
  const data = useLoaderData<typeof loader>();

  const locale = getLocale(useLocation().pathname) ?? i18n.fallbackLng;

  // This hook will change the i18n instance language to the current locale
  // detected by the loader, this way, when we do something to change the
  // language, this locale will change and i18next will load the correct
  // translation files
  // Works when you navigate to routes without rerendering layout.. (Like switching page language)
  useChangeLanguage(locale);

  const [theme] = useTheme();

  const matches = useMatches();

  const firstLocalisedLinks = getLocalisedLinks(matches);

  // Render links to localised versions
  // Localised links are taken from export const handle in child routes
  // In order links to ber rendered server-side on first page load, detect and pass to default state
  const [localisedLinks, setLocalisedLinks] = useState<LocalisedLink[]>(firstLocalisedLinks);

  useEffect(() => {
    if (data.meta.analyticsEnabled) {
      // @ts-expect-error Google
      window.dataLayer = window.dataLayer || [];
      // @ts-expect-error Google
      function gtag() {dataLayer.push(arguments);}
      // @ts-expect-error Google
      gtag('js', new Date());
      // @ts-expect-error Google
      gtag('config', 'G-CFHVSBP00M', {
        page_path: window.location.pathname
      });
    }
  }, []);

  useEffect(() => {
    setLocalisedLinks(getLocalisedLinks(matches));
  }, [matches]);

  return (
    <html
      lang={locale}
      className={classNames("h-full", theme ?? "")}>

    <head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <meta name="apple-mobile-web-app-title" content="CountLike.PRO" />
      <meta name="application-name" content="CountLike.PRO" />
      <meta name="msapplication-TileColor" content="#ff0000" />
      <meta name="theme-color" content="#ffffff" />
      <Meta />
      <Links />
      {/*
           TODO Icons
          */}
      {/* Internationalization links
             https://developers.google.com/search/docs/specialty/international/localized-versions?hl=r
             https://yandex.ru/support/webmaster/yandex-indexing/locale-pages.html
             https://yandex.ru/support/webmaster/robot-workings/managing-redirects.html#region
             https://technicalseo.com/seo-tools/hreflang/
          */}
      {localisedLinks.map((link) =>
        <link key={link.lang} rel="alternate" hrefLang={link.lang} href={link.absoluteHref} />
        )}
      <ThemeInitScript />

      {data.meta.showAds && !data.meta.isDevMode ? <>
          {locale == "ru" ?
          <YandexAdsInitScript /> :
          <GoogleAdSenseInitScript />
          }
      </> : null}
    </head>
    <body className="min-h-screen flex flex-col bg-slate-100 dark:bg-slate-800 dark:text-slate-400">
    <Header />
    <div className="container h-full w-full mx-auto py-6 px-4">
      <Outlet />
    </div>
    <Footer />
    <ScrollRestoration />
    <script
          dangerouslySetInnerHTML={{
            __html: `window.sentryDSN = ${data.meta.sentryDSN ? "\"" + data.meta.sentryDSN + "\"" : null}`
          }} />

    {data.meta.analyticsEnabled ?
        <>
        <YMInitializer
            accounts={[93142685]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true
            }}
            version="2" />

        <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-CFHVSBP00M}`} />

      </> :
        null}
    <Scripts />
    <Toaster />
    <LiveReload />
    </body>
    </html>);

}

function AppWithProviders() {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>);

}

export default withSentry(AppWithProviders);