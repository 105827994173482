import { UIMatch } from "@remix-run/react";

import i18n, { getLocale } from "~/i18n";

export interface LocalisedLink {
  lang: string;
  href: string;
  absoluteHref: string;
  isCurrent: boolean;
}

/**
 * Search each nested route in matches for "localisedLinks" passed in "handle" export
 * If found - create an array of LocalisedLink objects
 *
 * localisedLinks can be an array of available locales
 * or map, where key is locale and value is relative link
 *
 * If array is passed, then current url path will be used with provided locales,
 * if map, than explicit localized url will be used.
 */
export function getLocalisedLinks(matches: UIMatch[]): LocalisedLink[] {
  const links: LocalisedLink[] = [];

  let localisedLinks: null | Map<string, string> | string[] = null;
  let matchedMatch: UIMatch|null = null;

  // Find the first match with localisedLinks
  for (let i = matches.length - 1; i >= 0; i--) {
    localisedLinks = (matches[i].handle as { localisedLinks?: Map<string, string> })?.localisedLinks ?? null;

    if (localisedLinks !== null) {
      matchedMatch = matches[i];
      break;
    }
  }

  if (localisedLinks === null || matchedMatch === null) {
    return [];
  }

  const baseUrl = typeof process != "undefined"
    ? (process.env.BASE_URL ?? null)
    : window.location.origin;

  if (baseUrl === null) {
    throw new Error("Can't figure out baseURL. Check env var defined for server side");
  }
  const currentLocale = getLocale(baseUrl + matchedMatch.pathname);

  if (Array.isArray(localisedLinks)) {
    // Take matched URL path and strip out current locale from it
    const urlWithoutCurrentLocale = matchedMatch.pathname.replace(new RegExp(`^/${currentLocale}`), "");

    // For each of available locales add a link
    i18n.supportedLngs.forEach((locale) => {
      if (!(localisedLinks as string[]).includes(locale)) {
        return;
      }

      // Remove trailing slash
      let alternatePath = `/${locale}${urlWithoutCurrentLocale}`;
      if (alternatePath.endsWith('/')) {
        alternatePath = alternatePath.slice(0, -1);
      }

      links.push({
        href: alternatePath,
        absoluteHref: `${baseUrl}${alternatePath}`,
        lang: locale,
        isCurrent: locale === currentLocale,
      });
    });

    return links;
  }

  if (typeof localisedLinks === "object") {
    Object.entries(localisedLinks)
      .forEach(([key, value]) => {
        links.push(
          {
            href: value,
            absoluteHref: `${baseUrl}${value}`,
            lang: key,
            isCurrent: key === currentLocale,
          }
        );
      });

    return links;
  }

  throw new Error("Invalid localisedLinks type");
}