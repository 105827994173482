import { Link, useLocation } from "@remix-run/react";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "~/components/Breadcrumbs";
import DarkModeSwitcher from "~/components/DarkModeSwitcher";
import Logo from "~/components/Logo";
import { getLocale } from "~/i18n";


const Header: React.FC = () => {
  const { t } = useTranslation("translations");

  const [open, setOpen] = useState(false);
  const [isFixed, setFixed] = React.useState(false);

  const pathname = useLocation().pathname;
  const locale = getLocale(pathname);

  return (
    <header
      className={classNames("w-full transition-all duration-500 ease-in-out", {
        "bg-white shadow-xl text-primary-500": isFixed,
        "bg-primary-500": !isFixed
      })}
    >
      <nav className={classNames(
        "container relative flex items-center justify-between px-4 mx-auto transition-all duration-500 ease-in-out", {
          "py-3": !isFixed,
          "py-2": isFixed
        })}
      >
        <Link
          to={locale !== null && pathname !== `/${locale}` ? `/${locale}` : "/"}
          className={classNames(
            "flex flex-row items-center gap-3 hover:text-accent-300 border-none hover:border-none text-nowrap",
            "transition-all duration-300 ease-in-out text-white dark:text-white")}
        >
          <Logo /> {t("brand")}
        </Link>

        {/*<button onClick={() => setOpen(!open)} className="md:hidden">*/}
        {/*  {open ? null : (*/}
        {/*    <span>*/}
        {/*        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none"*/}
        {/*             viewBox="0 0 24 24"*/}
        {/*             stroke="currentColor" strokeWidth="2">*/}
        {/*          <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />*/}
        {/*        </svg>*/}
        {/*    </span>*/}
        {/*  )}*/}

        {/*  {open ? (*/}
        {/*    <span>*/}
        {/*        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20"*/}
        {/*             fill="currentColor">*/}
        {/*          <path fillRule="evenodd"*/}
        {/*                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"*/}
        {/*                clipRule="evenodd" />*/}
        {/*        </svg>*/}
        {/*    </span>*/}
        {/*  ) : null}*/}
        {/*</button>*/}

        <div>
          <DarkModeSwitcher />
          {/*<LinkMenuItem to={`/`}>Home</LinkMenuItem>*/}
        </div>
      </nav>
      <Breadcrumbs />
    </header>
  );
};

export default Header;