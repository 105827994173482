import React, { useEffect } from "react";

/**
 * If Yandex RTB init scripts are put directly into header
 * it causes hydration error.
 * To overcome this, I add it after the component is mounted
 *
 * Scripts cant be rendered here as nomrmal TSX markup,
 * as they will not be executed as react uses innerHTML to mount DOM.
 * So I create them manually
 */
const GoogleAdSenseInitScript: React.FC = () => {
  useEffect(() => {
    console.log("Loading Google AdSense...")
    const head = document.querySelector("head");
    if (!head) {
      console.error("Can't mount GoogleAdSense RTB script: head element not found");
      return;
    }

    const script = document.createElement("script");
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5971209848685273";
    script.crossOrigin = "anonymous";
    script.async = true

    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, []);

  return null;
};

export default GoogleAdSenseInitScript;