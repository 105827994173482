import { Link, useLocation, useMatches } from "@remix-run/react";
import classNames from "classnames";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Email from "~/components/Email";
import Logo from "~/components/Logo";
import { getLocalisedLinks, LocalisedLink } from "~/helpers/localisedLinks";
import { getLocale, getLocaleName } from "~/i18n";

const Footer = () => {
  const { t } = useTranslation("translations");

  const pathname = useLocation().pathname;
  const locale = getLocale(pathname);

  const matches = useMatches();
  const [localisedLinks, setLocalisedLinks] = useState<LocalisedLink[]>(
    getLocalisedLinks(matches)
  );

  useEffect(() => {
    setLocalisedLinks(getLocalisedLinks(matches));
  }, [matches]);

  return (
    <>
      <footer className="flex flex-col items-center py-16 text-white bg-primary-900 mt-auto">
        {localisedLinks.length > 1 ? (
          <ul className="flex flex-row flex-wrap justify-center gap-2 mb-12 px-3 text-sm">
            {localisedLinks.map((locale) => (
              <Fragment key={locale.lang}>
                {!locale.isCurrent ? (
                  <li>
                    <Link to={locale.href}>{getLocaleName(locale.lang)}</Link>
                  </li>

                ) : null}
              </Fragment>
            ))}
          </ul>
        ) : null}

        <div className="mb-16 text-sm flex flex-wrap justify-center gap-4">
          <Link to="/en/about">
            {t("about")}
          </Link>
          {locale === "ru" ? (
            <Link to="https://yandex.ru/profile/147088245496" target="_blank" rel="noreferrer">
              Отзывы, ошибки, предложения
            </Link>
          ) : null}

          <Email email="we@countlike.pro">
            {t("contact_us")}
          </Email>
        </div>
        <Link
          to={locale !== null && pathname !== `/${locale}` ? `/${locale}` : "/"}
          className={classNames(
            "flex flex-row items-center gap-3 hover:text-accent-300 border-none hover:border-none text-nowrap",
            "transition-all duration-300 ease-in-out text-white")}
        >
          <Logo /> {t("brand")}, © 2023
        </Link>
      </footer>
    </>
  );
};

export default Footer;