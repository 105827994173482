import React from "react";

const Logo: React.FC = () => {
  // @ts-ignore
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 60 60"
      xmlSpace="preserve"
      fill="currentColor"
      style={{ maxHeight: "100px", minHeight: "50px" }}
    >
      <path d="M4.6,34.4c0.4-0.1,0.8-0.1,1.1-0.1c0.2,0,0.5,0,0.7,0c0,0,0,0,0,0c0.5-2.2,1.5-4.5,2.7-6.8c5.7-10.2,17-17.6,26.4-17.2
          c2.9,0.1,5.1,1,6.7,2.7c2.7,2.8,3.8,6.3,3.2,10.2c-0.6,3.8-2.7,7.7-5.7,10.6c-0.2,1.2-0.6,2.3-1,3.3c4.4-3.2,7.8-8.4,8.5-13.6
          c0.7-4.5-0.6-8.6-3.7-11.8c-2-2.1-4.6-3.1-7.9-3.2c-10-0.4-22.1,7.4-28.1,18.2C6.2,29.2,5.1,31.9,4.6,34.4z" />
      <path d="M59.6,37.1c-0.4-0.3-1-0.3-1.3,0.2c-2,2.6-5.1,6.2-8.3,8.9C38.5,55.6,27.7,58.8,17,55.8c-2.5-0.7-5.3-2.7-7.3-5.2
          C9.2,51,8.6,51.3,8,51.5c2.3,3,5.5,5.4,8.5,6.2c2.7,0.8,5.5,1.1,8.2,1.1c8.6,0,17.3-3.7,26.3-11.2c3.4-2.8,6.6-6.6,8.6-9.2
          C60,38,60,37.4,59.6,37.1z" />
      <path d="M51.1,36.1c-0.5-0.4-1-0.8-1.3-1.3c-1.9,2.3-4.2,4.5-6.8,6.8c-4.7,3.9-12.5,7.2-18.6,5.9c-3.1-0.7-5.3-2.6-6.8-5.5
          c-2.2-4.8-0.1-10.1,3.4-14.2c0-0.4,0.1-0.8,0.2-1.1c0.2-1,0.6-1.9,1.1-2.8c-5.5,4.8-9.5,12.2-6.4,19c1.7,3.5,4.4,5.7,8,6.6
          c1.1,0.2,2.2,0.4,3.3,0.4c5.9,0,12.6-3,16.8-6.6C46.9,40.7,49.2,38.4,51.1,36.1z" />
      <path d="M1.8,25.4C9.4,13.1,21.1,5.3,34.6,3.3C40.7,2.4,47.6,2,52.1,5.7c2.7,2.2,4.3,5.7,4.7,10.4c0.2,2.8-0.1,5.5-1,8.3
          c0.6,0.2,1.1,0.5,1.6,1c1-3.1,1.4-6.2,1.2-9.4c-0.4-5.3-2.2-9.2-5.3-11.7c-5.1-4.2-12.4-3.7-18.9-2.8C20.3,3.5,8.2,11.6,0.2,24.4
          c-0.3,0.4-0.1,1,0.3,1.3C0.9,26,1.5,25.8,1.8,25.4z" />
      <path d="M6.2,36.2c-5.4-0.6-6.7,7.6-3.8,11.5C4,49.8,7,50.5,8.9,48.9C13,45.6,11.5,36.8,6.2,36.2z" />
      <path d="M56.2,34.5c1.6-1.3,2.2-4.2,1.2-6.2c-2.7-5.5-9.3-0.1-6.3,5C52.2,35.2,54.6,35.8,56.2,34.5z" />
      <path d="M36.2,14.5c1.8,0.6,3.3,1.8,4.2,2.7c0.9,0.9,1.4,1.7,1.6,1.6c0.1-0.1,0-1-0.9-2.2c-0.8-1.2-2.4-2.6-4.5-3.4
          c-2.1-0.7-4.2-0.6-5.6-0.1c-1.4,0.4-2.1,1-2,1.2c0.1,0.2,0.9-0.1,2.2-0.2C32.6,13.8,34.4,13.8,36.2,14.5z" />
      <path d="M21.4,52.6c-1.9-0.6-3.5-1.6-4.5-2.5c-1-0.9-1.6-1.6-1.7-1.4c-0.1,0.1,0.1,1,1.1,2.1c0.9,1.1,2.6,2.4,4.8,3.1
          s4.1,0.8,5.6,0.7c1.4-0.1,2.3-0.3,2.3-0.5c0-0.2-0.9-0.3-2.2-0.4C25.2,53.5,23.4,53.3,21.4,52.6z" />
      <path d="M53.6,19.7c0.1,0.1,0.6-0.4,0.9-1.3c0.3-0.9,0.5-2.4,0.2-3.9c-0.3-1.5-1.1-2.7-1.7-3.5c-0.7-0.7-1.3-1-1.4-0.8
          c-0.3,0.3,1.3,2,1.8,4.6C54,17.3,53.3,19.5,53.6,19.7z" />
      <path d="M34.6,44.3c1.4-0.3,3.2-0.9,5.1-2s3.2-2.5,4.1-3.7c0.9-1.1,1.2-2,1.1-2c-0.3-0.2-2.4,2.5-5.9,4.6c-3.4,2.2-6.7,2.8-6.6,3.2
          C32.4,44.5,33.3,44.5,34.6,44.3z" />
      <path d="M52.6,49.4c-1,0.9-2.4,2-4.2,3c-1.8,1-3.5,1.7-4.7,2.4c-1.2,0.6-2,1-1.9,1.2c0.1,0.2,0.9,0,2.2-0.3c1.3-0.4,3.1-1,5-2.1
          c1.9-1,3.4-2.3,4.3-3.5c0.9-1.1,1.2-2,1-2.1C54.1,47.9,53.6,48.6,52.6,49.4z" />
      <path
        d="M16.8,12.6c2.9-2.2,5.6-3.5,5.4-3.8c-0.1-0.3-3.1,0.5-6.2,2.7c-3.1,2.2-4.8,4.9-4.5,5.1C11.8,16.9,13.9,14.7,16.8,12.6z" />
      <path d="M23.1,26.9c-0.8,3.9-0.1,9.3,3.2,12.2c3.3,2.8,8.2,1.8,10.6-2.3c2.1-3.5,2-10.2-1.1-13.8C31.4,17.9,24.3,21.1,23.1,26.9z
          M29.2,23.3c0.8-0.4,2.1-0.6,3.4,0c1.2,0.6,2,1.4,2.5,2.1c0.5,0.7,0.7,1.1,0.5,1.3c-0.1,0.1-0.6-0.1-1.2-0.6
          C33.8,25.6,33,25,32,24.5c-0.9-0.4-1.9-0.4-2.5-0.3c-0.7,0.1-1,0.4-1.2,0.2C28.2,24.4,28.4,23.8,29.2,23.3z" />
    </svg>

  );
};

export default Logo;